<template>
<div class="wrapper wrapper-content basic list">
  <div class="ibox ">

    <div class="ibox-content">

      <div class="table-responsive">
        <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
          <thead>
            <tr>
              <th :colspan="fields.length">
                <Pills :items="groups" :value="groupId" @change="onChangeGroup" v-if="groups.length > 0" />       
                <SearchInput @input="keyword = $event" :value="keyword" @submit="onSearch(keyword, filter)" />
                <div class="search-input" style="margin-right: 0.5rem">
                  <select v-model="filter" class="form-control" >
                    <option value="all">전체</option>
                    <option value="id">ID</option>
                    <option v-if="model.name == 'Store' || model.name == 'StoreSearch'" value="district_main">지역</option>
                  </select>
                </div>
                <div v-if="showLimit" class="datelimit-form">
                  <div class="form-group">
                    <div class="select">
                      <select v-model="limitDays" class="form-control" @change="onChangeLimit">
                        <option :value="0">전체</option>
                        <option :value="1">지난 1일</option>
                        <option :value="7">지난 7일</option>
                        <option :value="30">지난 30일</option>
                        <option :value="90">지난 90일</option>
                        <option :value="-1">직접선택</option>
                      </select>
                    </div>

                    <div class="input">
                      <datetime v-model="limitDateStart" class="form-control" type="date" zone="Asia/Seoul" name="limit_date_start" :week-start="0" :placeholder="limitDateStart ? $moment(limitDateStart).format('YYYY-MM-DD') : ''" :disabled="limitDays !== -1" />
                      <div class="divine"></div>
                      <datetime v-model="limitDateEnd" class="form-control" type="date" zone="Asia/Seoul" name="limit_date_end" :week-start="0" :placeholder="limitDateEnd ? $moment(limitDateEnd).format('YYYY-MM-DD') : ''" :disabled="limitDays !== -1" />
                    </div>
                  </div>
                </div>  
              </th>
            </tr>

            <tr class="info">
              <th :colspan="fields.length">
                <div class="info">Showing {{page}} to {{Math.max(1, parseInt(total/size))}} of <strong>{{total}}</strong> entries</div>

                <div class="utils">
                  <button v-if="useExport && !showLimit" class="btn btn-util" @click="toggleUtil('excel')"><svg-icon name="excel"></svg-icon> Export to Excel</button>
                  <div v-else-if="useExport && showLimit">
                    <button class="btn btn-util">
                      <export-excel :name="exportName" :export-fields="exportFields" :data="exportItems" :before-generate="loadExport" style="display: inline-block;">
                        <svg-icon name="excel"></svg-icon> Export
                      </export-excel>
                    </button>
                  </div>
                </div>
              </th>
            </tr>

            <tr class="export" v-if="showExport && !showLimit">
              <th :colspan="fields.length">
                <div class="export-form">
                  <div class="form-group">
                    <div class="select">
                      <select v-model="exportDays" class="form-control" @change="onChangeExport">
                        <option :value="0">전체</option>
                        <option :value="1">지난 1일</option>
                        <option :value="7">지난 7일</option>
                        <option :value="30">지난 30일</option>
                        <option :value="90">지난 90일</option>
                        <option :value="-1">직접선택</option>
                      </select>
                    </div>

                    <div class="input">
                      <datetime v-model="exportDateStart" class="form-control" type="date" zone="Asia/Seoul" name="export_date_start" :week-start="0" :placeholder="exportDateStart" :disabled="exportDays !== -1" />
                      <div class="divine"></div>
                      <datetime v-model="exportDateEnd" class="form-control" type="date" zone="Asia/Seoul" name="export_date_end" :week-start="0" :placeholder="exportDateEnd" :disabled="exportDays !== -1" />
                    </div>

                    <span class="input-group-append">
                      <button type="button" class="btn btn-submit">
                        <export-excel :name="exportName" :export-fields="exportFields" :data="exportItems" :before-generate="loadExport">Export</export-excel>
                      </button>
                    </span>
                  </div>
                </div>
              </th>
            </tr>

            <tr class="fields">
              <template v-for="field in fields">
                <th scope="col" :key="field.key">{{field.label}}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in items" :key="i">
              <template v-for="field in fields">

                <template v-if="visible(field, 'uid')">
                  <td :key="field.key">{{row[field.key]}}</td>
                </template>

                <template v-if="visible(field, 'datetime')">
                  <td :key="field.key">{{fieldValue(field, row)}}</td>
                </template>

                <template v-if="visible(field, '*')">
                  <td v-if="model.name === 'Transaction' && field.key === 'user_name'" :key="field.key">{{ `${row.user_name}(${row.user_id})` }}</td>
                  <td v-else-if="model.name === 'Transaction' && field.key === 'type' && Object.keys(row.data).length > 0 && row.data.CardNo" :key="field.key">{{ `${row.type}(${row['data']['cardco'] || row['data']['CardName']}, ${row['data']['cardno4'] || row['data']['CardNo'].slice(-4)})` }}</td>                  
                  <td v-else-if="model.name === 'Transaction' && field.key === 'paid_total'" :key="field.key">{{ row[field.key] }} ₩</td>
                  <td v-else-if="model.name === 'Transaction' && field.key === 'transaction_name'" :key="field.key">{{ `${row[field.key]}(${row['target_id']})` }}</td>
                  <td v-else :key="field.key" :class="field.class" ><FilterValue :name="field.key" filter="all" :keyword="keyword" :value="fieldValue(field, row)"></FilterValue></td>
                </template>

                <template v-if="visible(field, 'resource')">
                  <td :key="field.key">
                    <progressive-background :src="getResource(field, row)" :aspect-ratio="1" class="thumbnail"></progressive-background>
                  </td>
                  <!-- <td><a :href="getResource(field, row)" target="_resource">DOWNLOAD</a><br />({{row['filetype']}})</td> -->
                </template>

                <template v-if="visible(field, 'resource_clickable')">
                  <td :key="field.key">
                    <!-- <progressive-background :src="getResource(field, row)" :aspect-ratio="1" class="thumbnail"></progressive-background> -->
                    <!-- <enlargeable-image :src="getResource(field, row)" :src_large="getResource(field, row)" class="thumbnail"/> -->
                    <div v-viewer="{ title: false }" class="thumbnail">
                      <img :src="getResource(field, row)">
                    </div>
                  </td>
                  <!-- <td><a :href="getResource(field, row)" target="_resource">DOWNLOAD</a><br />({{row['filetype']}})</td> -->
                </template>

                <template v-if="visible(field, 'action')">
                  <td :key="field.key" class="actions">
                    <button v-for="action in field.actions"
                      :key="action.action"
                      :disabled="!blockTransactionActions({...row, action})"
                      class="btn btn-xs" :class="getAction(action).class"
                      :style="{display: hideByTransactionActions({...row, action}) ? 'none' : ''}"
                      @click="getAction(action).onAction(row, { $router, $store, model, $dialog, $toast}, loadData)">{{getAction(action).label}}</button>
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td :colspan="fields.length" class="text-left">
                <Pagination :page="page" :size="size" :total="total" @page="onPage" />

                <div class="actions">
                  <div class="right">
                    <button v-for="action in actions"
                      :key="action.action"
                      class="btn btn-xs" :class="getAction(action).class"
                      @click="getAction(action).onAction({ $router, $store, model, $dialog })">
                        <fa-icon :icon="getAction(action).icon" v-if="getAction(action).icon" />
                        {{getAction(action).label}}
                      </button>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

    </div>
  </div>
</div>
</template>

<script>

import Pills from "@/components/elements/Pills"
import Pagination from "@/components/elements/Pagination"
import SearchInput from "@/components/utils/SearchInput"
import FilterValue from "@/components/utils/FilterValue"
import utils from "@/utils"
import 'viewerjs/dist/viewer.css'

export default {
  data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      items: [],
      fields: [],
      actions: [],

      groupId: '',
      groups: [],

      filter: '',
      keyword: '',

      showExport: false,
      exportItems: [],
      exportDays: 0,
      exportDateStart: '',
      exportDateEnd: '',

      showLimit: false,
      limitDays: 0,
      limitDateStart: '',
      limitDateEnd: ''
    }
  },
  created() {
    this.model = this.$route.meta.model

    this.$data.filter = this.$route.query.filter || 'all'
    this.$data.keyword = this.$route.query.keyword  || ''
    this.$data.page = this.$route.query.page && parseInt(this.$route.query.page) || this.page
    this.$data.size = this.$route.query.size && parseInt(this.$route.query.size) || this.size

    this.$data.groups = this.model.groups || []
    this.$data.fields = this.model.fields.filter(field => field.listing && field)
    this.$data.actions = this.model.actions.list || []

    this.$data.groupId = this.$route.query.group !== undefined ? this.$route.query.group
      : (this.model && this.model.getters && this.model.getters.defaultGroup || '');
    
    this.$data.showLimit = this.$route.meta.dateLimit || false

    if(this.$route.query.limitDays){
      this.$data.limitDays = parseInt(this.$route.query.limitDays);
      this.$data.limitDateStart = this.$route.query.date_start || '';
      this.$data.limitDateEnd = this.$route.query.date_end || '';
    }
  },
  async mounted() {
    this.$route.query.link === 'true' ?
        await this.onSearch(this.$data.filter, this.$data.keyword) :
        await this.loadData();
  },
  computed: {
    useExport() {
      return this.model.types.export ? true : false
    },
    useExportJson() {
      return this.model.types.exportJson ? true : false
    },
    exportName() {
      return `${this.model.name}.${this.$moment().format('YYYYMMDD')}.xls`
    },
    exportFields() {
      if (this.model.types.export) {
        return this.model.types.export.fields
      }
      return {}
    }
  },
  methods: {
    loadData(options = {}) {
      this.$data.loading = true
      this.$store.dispatch(this.model.types.list.action, {
        page: options.page || this.page,
        size: options.size || this.size,
        group: options.groupId || this.groupId,
        filter: options.filter || this.filter,
        keyword: options.keyword || this.keyword,
        date_start: options.date_start || this.limitDateStart,
        date_end: options.date_end || this.limitDateEnd
        })
        .then((data) => {
          let { items, total } = data

          this.$data.items = items
          this.$data.total = total
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
        .finally(() => {
          this.$data.loading = false
        })
    },

    loadExport() {
      let params = {
        group: this.groupId,
        date_start: this.limitDateStart || this.exportDateStart,
        date_end: this.limitDateEnd || this.exportDateEnd,
      }

      return new Promise((resolve, reject) => {
        this.$store.dispatch(this.model.types.export.action, params)
          .then((data) => {
            let { items, fields } = data

            if (items.length === 0) {
              this.$toast.error("추출할 데이타가 없습니다.")
            }

            this.$data.exportItems = items
            resolve(data)
          })
          .catch(e => {
            this.$toast.error(e.message)
            reject(e)
          })
      })
    },

    requestData(options = {}) {
      let { page, size, keyword, filter, limitDateStart, limitDateEnd } = options
      this.$data.page = page || this.page
      this.$data.size = size || this.size
      this.$data.keyword = keyword || this.keyword
      this.$data.filter = filter || this.filter
      this.$data.limitDateStart = limitDateStart || this.limitDateStart
      this.$data.limitDateEnd = limitDateEnd || this.limitDateEnd
      
      this.$router.replace({
        query: {
          page: this.page,
          size: this.size,
          group: this.groupId,
          keyword: this.keyword,
          filter: this.filter,
          limitDays: this.$data.limitDays,
          date_start: this.limitDateStart,
          date_end: this.limitDateEnd
        }
      })
    },

    visible(field, type) {
      if (field.type === type) {
        return true
      }

      if (type === '*') {
        return !['uid', 'datetime', 'resource', 'action', 'resource_clickable'].includes(field.type)
      }

      return false
    },

    getAction(action) {
      if (action === 'create') {
        return {
          action: "create",
          class: "btn-danger",
          icon: "edit",
          label: "생성",
          onAction: this.onClickCreate
        }
      }
      else if (action === 'edit') {
        return {
          action: "edit",
          class: "btn-success",
          label: "수정",
          onAction: this.onClickEdit
        }
      }
      else if (action === 'delete') {
        return {
          action: "delete",
          class: "btn-danger",
          label: "삭제",
          onAction: this.onClickDelete
        }
      }
      else if (action === 'view') {
        return {
          action: "view",
          class: "btn-info",
          label: "보기",
          onAction: this.onClickView
        }
      }
      else if (action === 'cancel') {
        return {
          action: "cancel",
          class: "btn-warning",
          label: "취소",
          onAction: this.onClickCancel
        }
      }
      else if (action === 'exportJson') {
        return {
          action: "export",
          class: "btn-success",
          label: "Export",
          onAction: this.onClickExportJson
        }
      }

      return action
    },

    getResource(field, row) {
      if(field.alias) {
        if(field.alias instanceof Function){
          return field.alias(field, row);
        }

        return field.alias;
      }

      return row[field.key]
    },

    getDeleteMessage(row) {
      return this.model.types.delete && this.model.types.delete.message && this.model.types.delete.message(row) || `${row.uid || row._id}(을)를 삭제하시겠습니까?`
    },

    getCancelMessage(row) {
      return this.model.types.cancel && this.model.types.cancel.message && this.model.types.cancel.message(row) || `${row.uid || row._id}(을)를 취소하시겠습니까?`
    },

    onSearch( keyword, filter ) {
      let options = {
        page: 1,
        keyword,
        filter
      }

      if(this.$data.showLimit){
        if((this.$data.limitDateStart && !this.$data.limitDateEnd) || (!this.$data.limitDateStart && this.$data.limitDateEnd)){
          this.$toast.error('날짜를 빠짐없이 선택해주세요.')
          return;
        }

        if(this.$data.limitDays === -1){
          this.$data.limitDateStart = this.$data.limitDateStart ? this.$moment(this.$data.limitDateStart).format('YYYY-MM-DD') : ''
          this.$data.limitDateEnd = this.$data.limitDateEnd ? this.$moment(this.$data.limitDateEnd).format('YYYY-MM-DD'): ''
        }

        options.date_start = this.$data.limitDateStart,
        options.date_end = this.$data.limitDateEnd
      }

      this.requestData(options)
      this.page = 1;
    },

    onPage({ page }) {
      this.requestData({ page })
    },

    // onSearch( keyword, filter ) {
    //   this.requestData({ page: 1, keyword: keyword, filter: filter })
    // },

    onChangeGroup({ label, value }) {
      this.$data.groupId = value
      this.requestData({
        page: 1
      })
    },

    onChangeExport(e) {
      let exportDays = parseInt(e.target.value)
      if (exportDays > 0) {
        this.$data.exportDateStart = this.$moment().subtract(exportDays - 1, 'days').format('YYYY-MM-DD')
        this.$data.exportDateEnd = this.$moment().format('YYYY-MM-DD')
      }
      else {
        this.$data.exportDateStart = ''
        this.$data.exportDateEnd = ''
      }
    },

    onChangeLimit(e){
      let limitDays = parseInt(e.target.value)

      if (limitDays > 0) {
        this.$data.limitDateStart = this.$moment().subtract(limitDays - 1, 'days').format('YYYY-MM-DD')
        this.$data.limitDateEnd = this.$moment().format('YYYY-MM-DD')
      }
      else {
        this.$data.limitDateStart = ''
        this.$data.limitDateEnd = ''
      }
    },

    onClickCreate() {
      this.$router.push({
        name: this.model.views.create.name,
        params: { }
      })
    },

    onClickView(row) {
      this.$router.push({
        name: this.model.views.view.name,
        params: this.model.views.view.params(row)
      })
    },

    onClickEdit(row) {
      this.$router.push({
        name: this.model.views.edit.name,
        params: this.model.views.edit.params(row)
      })
    },

    onClickDelete(row) {
      this.$dialog
        .confirm(this.getDeleteMessage(row), {
          okText: 'Delete',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onDelete(row)
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onClickCancel(row) {
      this.$dialog
        .confirm(this.getCancelMessage(row), {
          okText: '확인',
          cancelText: '닫기',
        })
        .then((dialog) => {
          this.onCancel(row)
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onDelete(row) {
      if (!(this.model.types.delete && this.model.types.delete.action)) {
        this.$toast.error('Delete Action 이 정의되지 않았습니다.')
        return
      }

      this.$store.dispatch(this.model.types.delete.action, row)
        .then((data) => {
          this.$toast.success("삭제되었습니다.")
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onCancel(row) {
      if (!(this.model.types.cancel && this.model.types.cancel.action)) {
        this.$toast.error('Delete Action 이 정의되지 않았습니다.')
        return
      }

      this.$store.dispatch(this.model.types.cancel.action, row)
        .then((data) => {
          this.$toast.success("취소되었습니다.")
          this.loadData()
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    fieldValue(field, row) {
      if (field.type === 'date' || field.type === 'datetime') {
        let dateValue = (field.alias) ? this.$utils.value(row, field.alias) : row[field.key]
        let date = new Date(dateValue)
        if (!date) {
          return 'Invalid Date'
        }
        return this.$moment(date).format(field.format)
      }

      if (this._.isString(field.alias)) {
        return this.$utils.value(row, field.alias)
      }
      else if (this._.isFunction(field.alias)) {
        return field.alias(field, row)
      }

      if( field.nested && field.parentUri ){
        return row[field.parentUri] && JSON.parse( row[field.parentUri] )[field.key] ? JSON.parse( row[field.parentUri] )[field.key] : '';
      }

      let value = row[field.key]
      if (typeof field.locale === 'function') {
        return field.locale(value)
      }

      if (typeof value === 'undefined') {
        return ''
      }

      if( field.type === 'field' || field.dataType == 'datetime'){
        let dateValue = (field.alias) ? this.$utils.value(row, field.alias) : row[field.key]
        let date = new Date(dateValue)
        if (!date) {
          return 'Invalid Date'
        }
        return this.$moment(date).format(field.format)
      }

      return value + ''
    },

    toggleUtil(action) {
      if (action === 'excel') {
        this.$data.showExport = !this.showExport
      }

      if (action === 'json') {
        this.$data.showExport = !this.showExport
      }
    },

    blockTransactionActions(options) {
      let { status, action } = options            

      if (typeof action === 'object'){
        action = action.action;
      }      

      if(this.$route.name === 'ListTransaction'){
        
        if(status.toLowerCase() === 'paid' && action === 'cancel'){
          return true;
        }

        if(status.toLowerCase() === 'fail' && action === 'complete'){          
          return true;
        }

        if(status.toLowerCase() === 'cancelled' && action === 'complete'){
          return true;
        }

        return false;
      }else{
        return true;
      }
    },

    hideByTransactionActions( options = {}){
      let { status, action } = options            

      if (typeof action === 'object'){
        action = action.action;
      }
      
      if(this.$route.name === 'ListTransaction'){
        
        if(status.toLowerCase() === 'fail' && action === 'cancel'){
          return true;
        }

        if(status.toLowerCase() === 'paid' && action === 'complete'){
          return true;
        }

        if(status.toLowerCase() === 'cancelled'){
          return true;
        }

        if(status.toLowerCase() === 'completed'){
          return true;
        }

        return false;
      }else{
        return false;
      }
    },

    async onClickExportJson(){
      let res = await this.$store.dispatch(this.model.types.exportJson.action)
      let data = {}, value

      const download = require('downloadjs')
      
      res.items.forEach( o => {
        value = JSON.parse(o.value)

        data[o.key] = value
      })
      
      data = new Blob([JSON.stringify(data, null, 2)], {
          type: 'application/json',
          name: 'language.json'
      });
      
      download(data, "language.json", "application/json");
    }

  },
  components: {
    Pills, SearchInput, Pagination, FilterValue
  }
}
</script>

<style lang="scss" scoped>
  .ellipsis{
    max-width: 15rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .datelimit-form{
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    line-height: 100%;

    .form-group{
      display: flex;
      margin: 0;

      & > .select{
        width: 4.38rem;
        margin-right: .5rem;
      }

      & > .input{
        display: flex;

        & > *{

          &:last-child{
            margin-left: 0.2rem;
          }
        }

        & > .form-control{
          width: 7.3rem;
        }
      }
    }
  }
</style>
